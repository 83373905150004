@import "src/styles/mixins";
@import "src/styles/shortcuts";

.RegisterPage {
  padding: 32px;
  @include beMobile() {
    padding: 32px 0 0;
  }
}

.login {
  max-width: 460px;
  margin: 0 auto;
  background-color: $white;
  padding: 40px 50px;
  box-shadow: 0 1px 1px $boxShadow;
  border-radius: 4px;
  @include beMobile() {
    max-width: 100%;
  }
  @include toIphoneSix() {
    padding: 20px 25px;
  }

  .loginBack {
    & > a {
      color: $blackText;
    }
  }
}

.login_nav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(182, 185, 193, 0.4);
}

.login_nav_item {
  flex-basis: 50%;
  max-width: 50%;
  position: relative;
  text-align: center;
}

.login_nav_item_active {
  color: black !important;
  border-bottom: solid 2px $yellowBorder;
}

.login_nav_item a {
  font-size: 1.625em;
  font-weight: normal;
  line-height: 1.5;
  padding-bottom: 10px;
  display: block;
  color: $headerLink;
}

.ResendAfter {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $link;
  margin-top: 8px;
  margin-bottom: 8px;
}

.Resend {
  @extend .ResendAfter;
  cursor: pointer;
}

.input_block {
  text-align: center;
  color: #86807a;

  & > form {
    font-weight: normal;

    & > div {
      margin-bottom: 16px;

      & > label {
        font-weight: normal;
      }
    }
  }
}

.button {
  margin: 0;
  transition: all 0.2s ease;
  display: inline-flex;
  min-height: 42px;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  background: $red;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: white;
  border: none;
  outline: none;
}

.button_red {
  background-color: $red;
  width: 100%;
}

.input {
  background: $white;
  border: 1px solid $lightGrayText;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px 16px;
  outline: none;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: $blackText;
  -webkit-appearance: none;
  height: auto;
  box-shadow: none;

  &:focus {
    border: 1px solid $blackText;
  }
}

.small_text {
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
  color: #828282;
  margin: 16px 0;
  font-weight: normal;

  & > a {
    color: $link;
  }
}

.alert {
  padding: 16px 28px 18px;
  position: relative;
  text-align: center;
  background-color: #fff8e3;
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 2px;
  border: solid 2px $yellowBorder;
}

.social_list {
  display: flex;
  padding: 4px;
  flex-wrap: nowrap;
  justify-content: center;

  @include beMobile() {
    flex-wrap: wrap;
  }
}

.social_item {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 1.5em;
  opacity: 0.7;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  padding: 8px;
}

.social_item:hover {
  text-decoration: none;
  opacity: 1;
  color: $white;
}

.forgot {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  cursor: pointer;
  color: $headerLink;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px transparent solid;
}

.forgotBlock {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  margin: 0 auto;
  background-color: $white;
  padding: 40px 50px;
  box-shadow: 0 1px 1px $boxShadow;
  border-radius: 4px;
  @include toIphoneSix() {
    padding: 20px 25px;
  }

  .forgotBlockError {
    & > span {
      padding: 16px 28px 18px;
      text-align: center;
      font-size: 1em;
      line-height: 1.5;
      margin-bottom: 15px;
      margin-top: 10px;
      border-radius: 2px;
      border: solid 2px $red;
      width: 100%;
      position: relative;

      & > p {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        margin-bottom: auto;
        justify-content: flex-end;
        margin-right: 4px;
        margin-top: 4px;
        line-height: 1;
        cursor: pointer;
      }
    }
  }
}

.forgotBlockHeader {
  margin-bottom: 16px;
  font-size: 1em;
  line-height: 1.5;

  & > span {
    cursor: pointer;
    font-weight: 500;
    color: black;
  }
}

.forgotBlockMain {
  display: flex;
  flex-direction: column;

  .forgotBlockMainNewPassword {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;

    & > span {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: $blackText;
    }

    & > label {
      & > input {
        background: $white;
        border: 1px solid $lightGrayText;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 13px 16px;
        outline: none;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: $blackText;
        -webkit-appearance: none;
        height: auto;
        box-shadow: none;

        &:focus {
          border: 1px solid $blackText;
        }
      }
    }
  }

  .forgotBlockInputs {
    .forgotBlockInputsAgain {
      text-align: right;
      color: #828282;
    }

    .forgotBlockInputsAgainClick {
      cursor: pointer;
      text-align: right;
      color: black;
    }

    & > span {
      color: #86807a;
    }

    & > label {
      & > span {
        color: #86807a;
        font-weight: normal;
      }

      & > input {
        background: $white;
        border: 1px solid $lightGrayText;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 13px 16px;
        outline: none;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: $blackText;
        -webkit-appearance: none;
        height: auto;
        box-shadow: none;

        &:focus {
          border: 1px solid $blackText;
        }
      }
    }
  }

  & > h1,
  h4 {
    color: black;
    font-weight: normal;
    margin: 8px 0;
  }

  & > h4 {
    margin: 8px 0 16px 0;
    color: #86807a;
  }

  & > div {
    display: flex;
    flex-direction: column;
    font-weight: normal;

    & > label {
      display: flex;
      align-items: center;

      & > span {
        margin-right: 16px;
        font-weight: normal;
      }

      & > input {
        width: 75%;
        display: block;
        height: 42px;
        transition: all 0.2s ease;
        margin: 0 0 0 auto;
        background: $white;
        border: 1px solid $lightGrayText;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 13px 16px;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: $blackText;
        -webkit-appearance: none;
        box-shadow: none;

        &:focus {
          border: 1px solid $blackText;
        }
      }
    }

    .forgotBlockMainPrompt {
      text-align: center;
      font-size: 1em;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 8px;
      color: #828282;
    }

    .forgotBlockMainPromptCode {
      @extend .forgotBlockMainPrompt;
      margin-left: 42px;
      margin-bottom: 8px;
      margin-top: 4px;
      text-align: right;
    }

    & > button {
      max-width: 100%;
      width: 100%;
      border: none;
      padding: 11px 20px;
      font-weight: 500;
      line-height: 1;
      border-radius: 2px;
      background-color: $red;
      margin: 0;
      color: $white;
      transition: all 0.2s ease;
      display: inline-flex;
      font-size: 1em;
      min-height: 42px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;
      margin-top: 16px;
      margin-bottom: 16px;

      &:hover {
        background-color: #d33636;
      }
    }

    .LastButton {
      max-width: 100%;
      width: 100%;
      border: none;
      padding: 11px 20px;
      font-weight: 500;
      line-height: 1;
      border-radius: 2px;
      background: $red;
      color: $white;
      transition: all 0.2s ease;
      display: inline-flex;
      font-size: 1em;
      min-height: 42px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;
      margin: 16px 0;

      &:hover {
        background: $red;
      }
    }
  }
}

.forgotBlockBottom {
  text-align: center;
  border-top: 1px solid rgba(182, 185, 193, 0.4);
  padding-top: 24px;
  margin-top: 24px;

  & > span {
    text-align: center;
    color: #86807a;
    font-weight: normal;

    & > a {
      color: $link;
      font-weight: normal;
      transition: 0.2s ease-in-out;

      &:hover {
        color: $linkActive;
      }
    }
  }
}

.sign_up_header {
  color: black;

  & > h2 {
    font-weight: normal;
    margin: 16px 0;
  }
}

.form_error {
  color: #a7272b;
  margin-top: 10px;
}

.form_error_providers {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
  margin: 16px 0 7px;
}

.TypePreloader {
  position: relative;
  min-width: 181px;
  display: flex;
  justify-content: center;
}

.TypePreloader:before,
.TypePreloader:after,
.TypePreloader *:after {
  content: "";
}

.TypePreloader:before,
.TypePreloader:after,
.TypePreloader * {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}
.TypePreloader * {
  margin: 0 10px;
}

.TypePreloader *:after {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  width: 10px;
  height: 10px;
  border-radius: inherit;
  background-color: inherit;

  animation: eye 1s linear infinite alternate;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  &Title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    &Span {
      color: #ff2a23;
      text-align: start;
    }
  }
  &Text {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    &Email {
      font-weight: 700;
    }
  }
  &Inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 32px;
  }
  &InputDefault {
    background: #f4f5f6;
    color: #b5b7ba;
  }
  &InputError {
    border: 1px solid #ce0000;
  }
  &Label {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 700;
    color: #4e5355;
    &Login {
      color: #b5b7ba;
    }
  }
  &Main {
    width: 256px;
  }
  &Btn {
    width: 100%;
    margin-bottom: 32px;
  }
  &Footer {
    font-size: 14px;
    font-weight: 400;
  }
  &Error {
    color: #ce0000;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
  }
}

@keyframes eye {
  from {
    left: 65px;
  }
  to {
    left: 105px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .Form {
    gap: 16px;
    &Title {
      font-size: 20px;
    }
    &Text {
      font-size: 14px;
    }
    &Main {
      width: 100%;
    }
    &Label {
      font-size: 12px;
    }
    &Inputs {
      gap: 14px;
      margin-bottom: 20px;
    }
    &Btn {
      margin-bottom: 20px;
    }
  }
  .TypePreloader {
    width: fit-content;
    margin: 0 auto;
  }
}

.warnBlock {
  margin: 0 auto;
  width: 660px;
  background-color: #fff8e3;
  padding: 20px;
  margin-bottom: 40px;
  border: solid 2px #F7C41C;
  border-radius: 10px;
  color: black;
}

@media (max-width: 768px) {
  .warnBlock {
    max-width: 750px;
    width: 100%;
  }
}
